.main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    font-family: "Montserrat", "sans-serif";
    margin-top: 12rem;
    margin-bottom: 2rem;
    text-align: center;
}

/* meidum screens */

@media all and (min-width: 768px) and (max-width: 1024px) {
    .main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        font-family: "Montserrat", "sans-serif";
        margin-top: 12rem;
        margin-bottom: 2rem;
        text-align: center;
    }
}

/* small screens */

@media all and (min-width: 320px) and (max-width: 767px) {
    .main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        font-family: "Montserrat", "sans-serif";
        margin-top: 12rem;
        margin-bottom: 2rem;
        text-align: center;
    }
}

.submain {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(0deg, #fff4c4, #fff);
}

.loginP {
    color: gray;
    margin-bottom: 50px;
    font-size: 18px;
}

.loginmain {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 60vh;
}

.sidebarMain {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -600px;
    height: 60vh;
}